import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService, RunService } from '@wtw/platform/services';
import { Observable, of } from 'rxjs';
import { delay, first, mergeMap } from 'rxjs/operators';
import { APP_CONSTANTS } from 'app/shared/helpers/ApplicationConstants';
import { RunViewModel } from 'app/api/dtos';

@Injectable({
  providedIn: 'root'
})
export class UrlRedirectGuard implements CanActivate {

  public projectId = 0;
  public destinationUrl = '';

  constructor(@Inject(TranslateService) private _translateService: TranslateService,
    @Inject(RunService) private _runService: RunService,
    @Inject(ConfigService) private _configService: ConfigService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this._runService.activeRun
      .pipe(first())
      .pipe(mergeMap(() => this._configService.settings, (activeRun, settings) => ({ activeRun, settings })))
      .subscribe(value => {
        const viewModel = value.activeRun.data as RunViewModel;
        this.projectId = viewModel.projectId;
        this.destinationUrl = this.getDestinationUrl(value.settings.modelManagerUrl, this.projectId);
        this.redirectToDestinationUrl(this.destinationUrl);
      });
    return false;
  }

  public getDestinationUrl(url: string, projectId: number): string {
    return `${url}/run/${projectId}/${this._translateService.instant('DESTINATION_MODEL.PAGE_NAME')}`;
  }

  public redirectToDestinationUrl(destinationUrl: string): void {
    of(destinationUrl)
      .pipe(delay(APP_CONSTANTS.redirectDelay))
      .uiSignal(
        {
          debugInfo: 'redirect to Model Manager',
          uiLabel: this._translateService.instant('DESTINATION_MODEL.REDIRECT_MESSAGE')
        }, null, true)
      .subscribe(redirectUrl => {
        window.location.href = redirectUrl;
      });
  }

  private getHostDomainName() {
    return window.location.host;
  }

}
