import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlHelperService {

  public static getApiUrl = () => {
    return UrlHelperService.getProtocolName() + `api.${UrlHelperService.getHostNameDomain()}`;
  }

  public static getPlatformUrl = () => {
    return UrlHelperService.getProtocolName() + `platform.${UrlHelperService.getHostNameDomain()}`;
  }

  static getHostNameDomain = () => {
    return window.location.host;
  }

  static getProtocolName = () => {
    return window.location.protocol + '//';
  }

}
